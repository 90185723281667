import React from "react"
import { navigate } from "gatsby"
import WebsiteContext from "../../../context/contents/websiteContext"
import { SitePagesContext } from "../../../root/common/context/pages"

interface Props {
  languageId: string
}

export const LocalePageRedirect = ({ languageId }: Props) => {
  const website = React.useContext(WebsiteContext)
  const sitePages = React.useContext(SitePagesContext)

  React.useEffect(() => {
    if (!languageId) {
      return
    }

    const websiteIndex = website.getData(languageId)
    const homeUrl = sitePages.getPagePath(websiteIndex.home.id) ?? "/home"
    navigate(homeUrl)
  }, [languageId])

  return <></>
}

export default LocalePageRedirect
